@import "@palette";

.root {
	background: $color-common-background;
}

.layout {
	composes: globalWidth from global;
}

.tags {
	align-items: center;

	@media (max-width: $mobile-break) {
		flex-direction: column;
		align-items: start;

		&--container {
			max-width: 100%;
		}
	}

	@media (min-width: $mobile-break+1) {
		&--all {
			max-width: 75%;
		}
	}

	&--icon {
		width: 10px;
		height: 10px;
		margin-right: 8px;

		path {
			fill: #5D697D;
		}

		&-active {
			composes: tags--icon;

			path {
				fill: white;
			}
		}
	}

	&--container {
		cursor: pointer;
		height: 28px;

		/* Gray 5 */
		background: #D6DBE6;
		border-radius: 4px;
		padding: 10px 12px;

		&-active {
			composes: tags--container;
			background: #929EB0;
		}
	}

	&--text {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		/* Gray 2 */
		color: #5D697D;
		margin: 0;

		&-active {
			composes: tags--text;
			color: #FFFFFF;
		}
	}
}

.head-list {
	p {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		/* Gray 2 */
		color: #5D697D;
		margin: 0 0 0 6px;


	}

	p:nth-of-type(2) {
		padding: 3px 6px;

		background: #D6DBE6;
		border-radius: 10px;
	}

}
.navigate-wrapper {
	gap: 20px;
	justify-content: space-between;
	margin-bottom: 20px;
	& a {
		width: 49%;
		min-width: 250px;
	}
	.navigate {
		display: flex;
		padding: 18px 20px 18px 16px;
		align-items: center;
		justify-content: space-between;
		border-radius: 10px;
		background-color: $color-gray-6;
		width: 100%;

		&:hover {
			background-color: $color-violet-light-2;

			span {
				background-color: $color-violet-1;
				color: $color-white;
			}

		}

		&-item {
			display: flex;
			align-items: center;
			gap: 10px;

			span {
				font-size: 10px;
				font-style: normal;
				font-weight: 600;
				line-height: 12px;
				border-radius: 10px;
				padding: 3px 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $color-gray-5;
				color: $color-gray-2;
				text-align: center;
			}
		}
	}
	@media (max-width: 660px) {
		flex-wrap: wrap;
		& a {
			width: 100%;
		}
	}
}